@import '../../assets/scss/mixin';

.container {
  .pcOnly {
    display: initial;
  }

  .mobileOnly {
    display: none;
  }

  @include mobile-container {
    .pcOnly {
      display: none;
    }
    .mobileOnly {
      display: initial;
    }
  }
}
