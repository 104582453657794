@import '../../../../assets/scss/mixin';

.container {
  display: flex;
  align-items: center;

  .ranking {
    color: var(--whiteA75, rgba(255, 255, 255, 0.75));
    text-align: center;
    text-overflow: ellipsis;
    margin: 0 1rem;
    @include font-body-04-reading-reg;
  }

  .contentWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width: 1rem;
    margin-right: 0.75rem;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .titleWrapper {
      display: flex;
      gap: 0.25rem;
      align-items: center;

      .title {
        color: var(--whiteA90, rgba(255, 255, 255, 0.9));
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        @include font-body-05-reading-reg;
      }
    }

    .subTitle {
      overflow: hidden;
      color: var(--whiteA50, rgba(255, 255, 255, 0.5));
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      @include font-body-06-normal-reg;
    }
  }
}
