@import '../../assets/scss/mixin';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  .title,
  .description {
    text-align: center;
  }
  .title {
    @include font-body-03-reading-med;
    color: var(--white, var(--Colors-Base-Neutral-White-white, #fff));
  }
  .description {
    @include font-body-05-reading-reg;
    color: var(--whiteA50, rgba(255, 255, 255, 0.5));
  }
}
