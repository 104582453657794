@import '../../assets/scss/mixin';

.container {
  padding: 3rem 1.5rem 0.5rem 1.5rem;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sectionTitle {
    @include font-body-02-normal-med;
    color: #fff;
    margin: 0;
  }

  .viewMoreButton {
    cursor: pointer;
    color: var(
      --whiteA50,
      var(--color-palette-neutral-alpha-500, rgba(255, 255, 255, 0.5))
    );
    text-align: right;
    @include font-body-05-reading-reg;
  }
}

@include mobile-container {
  .container {
    padding: 3rem 0 0.5rem;
  }

  .sectionHeader {
    padding: 0 1rem;
  }
}