@import '../../../../assets/scss/mixin';

.container {
  position: relative;
  margin: 12px auto;
  display: flex;
  width: 100%;
}

.prevBtn {
  left: -3rem;
}

.nextBtn {
  right: -3rem;
}

.prevBtn,
.nextBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: block;
  z-index: 1;
}

.carouselItems {
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: row;
  display: flex;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

@include mobile-container {
  .prevBtn,
  .nextBtn {
    display: none;
  }
  .carouselItems {
    gap: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@include pc-container {
  .container {
    .prevBtn {
      left: -0.1875rem;
    }

    .nextBtn {
      right: -0.1875rem;
    }
  }
}
