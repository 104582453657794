@import './variables';
@import './fonts';

// PC 스타일 우선 적용. PC 스타일에서는 media query를 사용하지 않음.
// 모바일 사이즈 스타일 적용(~ 768px)

// 미디어 쿼리용
@mixin mobile-screen {
  @media (max-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin tablet-screen {
  @media (max-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin pc-screen {
  @media (max-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin max-screen {
  @media (min-width: #{$breakpoint-xlg}) {
    @content;
  }
}

// 컨테이너 쿼리용
@mixin mobile-container {
  @media (max-width: #{$breakpoint-sm}) {
    @content;
  }

  @supports (container-type: inline-size) {
    @container story-game-home-tab-container (max-width: #{$breakpoint-sm}) {
      @content;
    }
  }
}

// 태블릿 사이즈 스타일 적용(~ 1024px)
@mixin tablet-container {
  @media (max-width: #{$breakpoint-md}) {
    @content;
  }

  @supports (container-type: inline-size) {
    @container story-game-home-tab-container (max-width: #{$breakpoint-md}) {
      @content;
    }
  }
}

// PC 사이즈 스타일 적용(1024px ~ 1280px)
@mixin pc-container {
  @media (max-width: #{$breakpoint-lg}) {
    @content;
  }

  @supports (container-type: inline-size) {
    @container story-game-home-tab-container (max-width: #{$breakpoint-lg}) {
      @content;
    }
  }
}

// 최대 PC 사이즈 범위 이상 스타일 적용(1920px ~)
@mixin max-container {
  @media (max-width: #{$breakpoint-xlg}) {
    @content;
  }

  @supports (container-type: inline-size) {
    @container story-game-home-tab-container (max-width: #{$breakpoint-xlg}) {
      @content;
    }
  }
}
