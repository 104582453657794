@mixin font-family {
  font-family: 'Pretendard Variable', 'Apple SD Gothic Neo', -apple-system,
    BlinkMacSystemFont, helvetica, Arial, sans-serif;
}

// display
@mixin font-display-01-med {
  /* playnovel/display01/Med */
  @include font-family;
  font-size: var(--font-heading-3xl, 2rem);
  font-style: normal;
  font-weight: 500;
  line-height: 2.625rem; /* 131.25% */
  letter-spacing: var(--letter-spacing-en, 0rem);
}

// heading
@mixin font-heading-01-bold {
  /* playnovel/heading01/Bold */
  @include font-family;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.125rem; /* 121.429% */
}
@mixin font-heading-01-med {
  /* playnovel/heading01/Med */
  @include font-family;
  font-size: var(--font-heading-xxl, 1.75rem);
  font-style: normal;
  font-weight: 500;
  line-height: 2.125rem; /* 121.429% */
  letter-spacing: var(--letter-spacing-en, 0rem);
}
@mixin font-heading-02-bold {
  /* playnovel/heading02/Bold */
  @include font-family;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem; /* 125% */
}
@mixin font-heading-02-med {
  /* playnovel/heading02/Med */
  @include font-family;
  font-size: var(--font-heading-xl, 1.5rem);
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem; /* 125% */
  letter-spacing: var(--letter-spacing-en, 0rem);
}
@mixin font-heading-03-bold {
  /* playnovel/heading03/Bold */
  @include font-family;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem; /* 127.273% */
}
@mixin font-heading-03-med {
  /* playnovel/heading03/Med */
  @include font-family;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem; /* 127.273% */
}

// body
@mixin font-body-01-normal-bold {
  @include font-family;
  /* playnovel/body01/Normal-Bold */
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.625rem; /* 130% */
}
@mixin font-body-01-normal-med {
  @include font-family;
  /* playnovel/body01/Normal-Med */
  font-size: var(--font-heading-m, 1.25rem);
  font-style: normal;
  font-weight: 500;
  line-height: 1.625rem; /* 130% */
  letter-spacing: var(--letter-spacing-en, 0rem);
}
@mixin font-body-01-reading-bold {
  @include font-family;
  /* playnovel/body01/Reading-Bold */
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem; /* 150% */
}
@mixin font-body-01-reading-reg {
  @include font-family;
  /* playnovel/body01/Reading-Reg */
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem; /* 150% */
}
@mixin font-body-02-normal-bold {
  @include font-family;
  /* playnovel/body02/Normal-Bold */
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem; /* 133.333% */
}
@mixin font-body-02-normal-med {
  @include font-family;
  /* playnovel/body02/Normal-Med */
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 133.333% */
}
@mixin font-body-02-reading-bold {
  @include font-family;
  /* playnovel/body02/Reading-Bold */
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.625rem; /* 144.444% */
}
@mixin font-body-02-reading-reg {
  @include font-family;
  /* playnovel/body02/Reading-Reg */
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem; /* 144.444% */
}
@mixin font-body-02-highlight-bold {
  @include font-family;
  /* playnovel/body02/Highlight-Bold */
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.625rem; /* 144.444% */
  text-decoration-line: underline;
}
@mixin font-body-03-reading-bold {
  @include font-family;
  /* playnovel/body03/Reading-Bold */
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem; /* 150% */
}
@mixin font-body-03-reading-med {
  @include font-family;
  /* playnovel/body03/Reading-Med */
  font-size: var(--font-heading-xs, 1rem);
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
  letter-spacing: var(--letter-spacing-en, 0rem);
}
@mixin font-body-03-reading-reg {
  @include font-family;
  /* playnovel/body03/Reading-Reg [Default] */
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
}
@mixin font-body-03-strike-bold {
  @include font-family;
  /* playnovel/body03/Strike-Bold */
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem; /* 150% */
  text-decoration-line: strikethrough;
}
@mixin font-body-03-strike-reg {
  @include font-family;
  /* playnovel/body03/Strike-Reg */
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  text-decoration-line: strikethrough;
}
@mixin font-body-03-highlight-bold {
  @include font-family;
  /* playnovel/body03/Highlight-Bold */
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem; /* 150% */
  text-decoration-line: underline;
}
@mixin font-body-03-highlight-reg {
  @include font-family;
  /* playnovel/body03/Highlight-Reg */
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  text-decoration-line: underline;
}
@mixin font-body-04-reading-bold {
  @include font-family;
  /* playnovel/body04/Reading-Bold */
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem; /* 146.667% */
}
@mixin font-body-04-reading-med {
  @include font-family;
  /* playnovel/body04/Reading-Med */
  font-size: var(--font-heading-xxs, 0.9375rem);
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem; /* 146.667% */
  letter-spacing: var(--letter-spacing-en, 0rem);
}
@mixin font-body-04-reading-reg {
  @include font-family;
  /* playnovel/body04/Reading-Reg */
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 146.667% */
}
@mixin font-body-05-reading-bold {
  @include font-family;
  /* playnovel/body05/Reading-Bold */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem; /* 142.857% */
}
@mixin font-body-05-reading-med {
  @include font-family;
  /* playnovel/body05/Reading-Med */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}
@mixin font-body-05-reading-reg {
  @include font-family;
  /* playnovel/body05/Reading-Reg */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
}
@mixin font-body-05-strike-bold {
  @include font-family;
  /* playnovel/body05/Strike-Bold */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem; /* 142.857% */
  text-decoration-line: strikethrough;
}
@mixin font-body-05-strike-reg {
  @include font-family;
  /* playnovel/body05/Strike-Reg */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  text-decoration-line: strikethrough;
}
@mixin font-body-05-highlight-bold {
  @include font-family;
  /* playnovel/body05/Highlight-Bold */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem; /* 142.857% */
  text-decoration-line: underline;
}
@mixin font-body-05-highlight-reg {
  @include font-family;
  /* playnovel/body05/Highlight-Reg */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  text-decoration-line: underline;
}
@mixin font-body-06-normal-bold {
  @include font-family;
  /* playnovel/body06/Normal-Bold */
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem; /* 138.462% */
}
@mixin font-body-06-normal-reg {
  @include font-family;
  /* playnovel/body06/Normal-Reg */
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 138.462% */
}
@mixin font-body-06-strike-bold {
  @include font-family;
  /* playnovel/body06/Strike-Bold */
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem; /* 138.462% */
  text-decoration-line: strikethrough;
}
@mixin font-body-06-strike-reg {
  @include font-family;
  /* playnovel/body06/Strike-Reg */
  font-family: 'Pretendard Variable';
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 138.462% */
  text-decoration-line: strikethrough;
}
@mixin font-body-06-highlight-bold {
  @include font-family;
  /* playnovel/body06/Highlight-Bold */
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem; /* 138.462% */
  text-decoration-line: underline;
}
@mixin font-body-06-highlight-reg {
  @include font-family;
  /* playnovel/body06/Highlight-Reg */
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 138.462% */
  text-decoration-line: underline;
}

// caption
@mixin font-caption-01-bold {
  @include font-family;
  /* playnovel/caption01/Bold */
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem; /* 133.333% */
}
@mixin font-caption-01-reg {
  @include font-family;
  /* playnovel/caption01/Reg */
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 133.333% */
}
@mixin font-caption-01-strike-bold {
  @include font-family;
  /* playnovel/caption01/Strike-Bold */
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem; /* 150% */
  text-decoration-line: strikethrough;
}
@mixin font-caption-01-strike-reg {
  @include font-family;
  /* playnovel/caption01/Strike-Reg */
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 133.333% */
  text-decoration-line: strikethrough;
}
@mixin font-caption-01-highlight-bold {
  @include font-family;
  /* playnovel/caption01/Highlight-Bold */
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem; /* 133.333% */
  text-decoration-line: underline;
}
@mixin font-caption-02-bold {
  @include font-family;
  /* playnovel/caption02/Bold */
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0.8125rem; /* 130% */
}
@mixin font-caption-02-med {
  @include font-family;
  /* playnovel/caption02/Med */
  font-size: var(--font-label-xs, 0.625rem);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: 0.8125rem; /* 130% */
  letter-spacing: var(--letter-spacing-en, 0rem);
}
@mixin font-caption-02-reg {
  @include font-family;
  /* playnovel/caption02/Reg */
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.8125rem; /* 130% */
}
