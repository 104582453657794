@import '../../../../assets/scss/mixin';

.storyContainer::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.storyContainer {
  padding: 0.75rem 1.5rem;
  flex: 1;
  display: flex;

  .mainMediaWrapper,
  .contentWrapper {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
  }

  .mainMediaWrapper,
  .infoWrapper {
    cursor: pointer;
  }

  .mainMediaWrapper {
    position: relative;
    border-radius: 0.5rem;
    flex-grow: 6;
    width: 100%;
    padding-bottom: 32.25%;

    .playerIconWrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    img {
      position: absolute;
      border-radius: 0.5rem;
      flex-basis: auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    video {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 0.5rem;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem 0 1.5rem 1.5rem;
    flex-grow: 4;
    white-space: normal;
    overflow-wrap: break-word;

    .linkButton {
      width: fit-content;
      padding: 0.75rem 1rem;
      margin-top: 1rem;
      border-radius: 0.375rem;
      border: 0.0625rem solid var(--whiteA50, rgba(255, 255, 255, 0.5));
      color: var(--whiteA90, rgba(255, 255, 255, 0.9));
      cursor: pointer;
      @include font-body-03-reading-reg;
    }

    .title {
      color: var(--white, #fff);
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 4px;
      width: 100%;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;

      @include font-heading-01-med;
    }

    .subTitle {
      color: var(
        --whiteA50,
        var(--color-palette-neutral-alpha-500, rgba(255, 255, 255, 0.5))
      );
      text-overflow: ellipsis;
      margin-bottom: 8px;
      width: 100%;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;

      @include font-body-06-normal-reg;
    }

    .description {
      color: var(
        --whiteA75,
        var(--transparent-white75, rgba(255, 255, 255, 0.75))
      );
      text-overflow: ellipsis;
      width: 100%;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;

      @include font-body-05-reading-reg;
    }
  }

  .contentWrapper.center {
    justify-content: center;
  }

  .mediaContainer {
    display: flex;
    gap: 0.75rem;
    width: 100%;
    justify-content: space-between;

    .previewMediaWrapper::before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }

    .previewMediaWrapper {
      position: relative;
      cursor: pointer;
      flex: 1;
      transform: translateY(-100%);

      img {
        opacity: 0.5;
        position: absolute;
        border-radius: 0.25rem;
        width: 100%;
        height: 100%;
        object-fit: cover; /* 이미지를 16:9 비율에 맞게 조정 */
      }

      video {
        opacity: 0.5;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 0.25rem;
        object-fit: cover;
      }

      .thumbnailPlayIcon {
        position: absolute;
        top: 150%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
      }
    }

    .previewMediaWrapper.selected {
      img,
      video {
        opacity: 1;
      }

      .imageWrapper {
        border: 0.0625rem solid rgba(255, 255, 255, 0.75);
        position: relative;
      }
    }
  }
}
