@import '../../../../assets/scss/mixin';

$pc-width-large: 22.5rem;
$pc-width-medium: 16.5rem;
$pc-width-small: 12.875rem;
$mobile-width-large: 19.5rem;
$mobile-width-medium: 13.5rem;
$mobile-width-small: 13.5rem;

.container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0 0.75rem;

  &.large {
    flex: 0 0 33.333333333%;
  }

  &.medium {
    flex: 0 0 25%;
  }

  &.small {
    flex: 0 0 20%;
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.26%;
  overflow: hidden;
}
.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.titleWrapper {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  margin-top: 0.5rem;
}

.adultIcon {
  height: 1rem;
  width: 1rem;
}

.title {
  color: #fff;
  white-space: nowrap;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;

  &.large {
    @include font-body-02-reading-reg;
  }
  &.medium {
    @include font-body-03-reading-reg;
  }
  &.small {
    @include font-body-04-reading-reg;
  }
}
.subTitle {
  @include font-body-06-normal-reg;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 0.125rem;
  white-space: nowrap;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}
.description {
  display: -webkit-box;
  @include font-body-05-reading-reg;
  color: rgba(255, 255, 255, 0.75);
  margin-top: 0.375rem;
  white-space: nowrap;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}
.title,
.subTitle,
.description {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
.progressLabelWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  margin-top: 0.375rem;
  .progressLabel {
    @include font-caption-01-reg;
    color: rgba(255, 255, 255, 0.25);
    &.active {
      color: #9457eb;
    }
  }
  .verticalLine {
    width: 0.0625rem;
    height: 0.5625rem;
    background: rgba(255, 255, 255, 0.1);
    margin-top: -0.125rem;
  }
}
.progressWrapper {
  position: relative;
  width: 100%;
  height: 0.25rem;
  margin-top: 0.25rem;
  & .progressBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.25rem;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 0.25rem;
    z-index: 1;
  }
  & .progressBar {
    position: absolute;
    top: 0;
    left: 0;
    height: 0.25rem;
    background: linear-gradient(263deg, #8f58d5 0%, #753bbd 100%);
    border-radius: 0.25rem;
    z-index: 2;
  }
}

@include mobile-container {
  .container {
    padding: 0;
  }
  .container .large {
    width: $mobile-width-large;
  }
  .container .medium {
    width: $mobile-width-medium;
  }
  .container .small {
    width: $mobile-width-small;
  }
}
