@import '../../../../assets/scss/mixin';

.carouselOuter {
  width: 100%;
  padding-bottom: 32.3%;
  position: relative;
  overflow: hidden;

  .carouselList {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% * 3);
    height: 100%;
    font-size: 0;
    list-style: none;
    transition: transform 1s;
    display: flex;
  }
}

.container {
  position: relative;
  display: flex;
  padding: 0.75rem 0;
  flex-direction: column;
  width: 100%;

  .sectionTitle {
    color: var(--white, #fff);
    display: flex;
    padding: 3rem 1.5rem 1.25rem 1.5rem;
    align-items: center;
    align-self: stretch;
    @include font-body-02-normal-med;
  }

  .storyCarouselArrow {
    position: absolute;
    margin: 2.5rem 1rem 0 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .storyCarouselArrow.left {
    left: -3rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .storyCarouselArrow.right {
    right: -3rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

@include pc-container {
  .container {
    .storyCarouselArrow.left {
      left: -0.75rem;
    }

    .storyCarouselArrow.right {
      right: -0.75rem;
    }
  }
}
