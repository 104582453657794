@import '../../assets/scss/mixin';

.container {
  display: flex;
  width: 100%;

  .scrollContainer {
    display: flex;
    width: 100%;
  }

  .storyList {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    max-width: 50%;

    .rankingListHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3rem 1.5rem 0.5rem;

      .rankingListTitle {
        color: var(--white, #fff);
        @include font-body-02-normal-med;
      }

      .viewMoreButton {
        cursor: pointer;
        color: var(
          --whiteA50,
          var(--color-palette-neutral-alpha-500, rgba(255, 255, 255, 0.5))
        );
        text-align: right;
        @include font-body-05-reading-reg;
      }
    }

    .storyItemWrapper {
      width: 100%;
      display: flex;
      cursor: pointer;
      align-items: center;
      margin: 0.75rem 0;
      padding: 0 1.5rem;
      cursor: pointer;

      img {
        border-radius: 0.25em;
        image-rendering: auto;
        object-fit: cover;
      }
    }
  }

  @include mobile-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    .scrollContainer {
      display: flex;
      flex: 0 0 auto;
      gap: 1rem;
      width: var(--scrollWidthForMobile);
      height: 100%;
      transition: transform 1s;
    }

    .storyList {
      flex: initial;
      max-width: initial;

      .rankingListHeader {
        padding: 3rem 1rem 0.5rem;
      }

      .storyItemWrapper {
        padding: 0 1rem;
      }
    }
  }
}
