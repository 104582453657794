@import '../../../../assets/scss/mixin';

$pc-width: 34.5rem;
$mobile-width: 19.5rem;

.container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-right: 1.5rem;
  flex: 0 0 50%;
  cursor: pointer;
}

.imageWrapper {
  position: relative;
  height: 0;
  width: 100%;
  padding-top: 56.26%;
  overflow: hidden;
  flex: 0 0 50%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.rankTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
}

.rank {
  font-family: 'Noto Sans';
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 500;
  line-height: 3.5rem; /* 140% */
  letter-spacing: 0;
  color: rgba(255, 255, 255, 0.9);
  min-width: 2.5rem;
  text-align: center;
}

.titleWrapper {
  display: flex;
  gap: 0.25rem;
}

.title {
  @include font-body-01-normal-med;
  color: rgba(255, 255, 255, 0.9);
  flex: 1;
  white-space: nowrap;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

@include mobile-container {
  .container {
    gap: 0.5rem;
    padding-right: 0;
    flex: initial;
  }
  .container,
  .imageWrapper {
    width: $mobile-width;
  }

  .rankTitleWrapper {
    width: $mobile-width;
  }
  .title {
    @include font-body-02-normal-med;
  }
}
