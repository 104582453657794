@import '../../assets/scss/mixin';

.container {
  width: 100%;
  font-size: 1.5rem;
  background-color: #fff;
}

.mainHeader {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.25rem 1.5rem;
  background-color: #fff;
  z-index: 10;
}

.leftWrapper {
  display: flex;
}

.mainLogoWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 2rem;
  gap: 0.25rem;
  cursor: pointer;
}

.mainLogoIcon {
  overflow: hidden;
  color: black;
  text-overflow: ellipsis;
  margin-top: 0.25rem;
  @include font-body-02-normal-med;
}

.logoWrapper {
  flex-direction: row;
  align-items: flex-start;
  margin-right: 2rem;
  gap: 0.25rem;
  display: none;
  cursor: pointer;
}

.logoIcon {
  margin-right: 2rem;
  overflow: hidden;
  color: black;
  text-overflow: ellipsis;
  cursor: pointer;
  display: none;
  @include font-body-02-normal-med;
}

.logoIcon.withAi {
  margin-right: 0;
  margin-top: 0.25rem;
}

.logoIcon.main {
  display: initial;
}

.tabWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.25rem;
  margin-right: 1rem;
  cursor: pointer;
}

.tab {
  color: #7e8185;
  margin-top: 0.25rem;
  @include font-body-03-reading-reg;
}

.tab.active {
  color: #000;
}

.rightWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  align-self: flex-end;
}

.icon {
  cursor: pointer;
}

.notificationIcon {
  display: none;
}

.categoryList {
  display: flex;
  justify-content: flex-start;
  margin-top: 4.25rem;
  padding: 1.25rem 1.5rem;
  gap: 1rem;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.category {
  min-width: fit-content;
  color: #7e8185;
  border-bottom: 0.125rem solid transparent;
  cursor: pointer;

  @include font-body-03-reading-reg;
}

.category.active {
  color: #000;
  border-bottom: 0.125rem solid #000;
}

.container.dark {
  background-color: #000;
}

.dark {
  & .tab {
    color: var(--whiteA50, rgba(255, 255, 255, 0.5));
  }

  & .tab.active {
    color: var(--white, var(--Colors-Base-Neutral-White-white, #fff));
  }

  & .mainHeader {
    background-color: #000;
  }

  & .category {
    color: var(--whiteA50, rgba(255, 255, 255, 0.5));
  }

  & .category.active {
    color: var(--white, var(--Colors-Base-Neutral-White-white, #fff));
    border-bottom: 0.125rem solid white;
  }

  & .mainHeader > .leftWrapper > .logoWrapper > .logoIcon {
    color: var(--white, var(--Colors-Base-Neutral-White-white, #fff));
  }
}

@include tablet-screen {
  .tab {
    display: none;
  }

  .tabWrapper {
    display: none;
  }

  .profileIcon {
    display: none;
  }

  .notificationIcon {
    display: inline;
  }

  .mainLogoWrapper {
    display: none;
  }

  .logoWrapper {
    display: flex;
  }

  .logoIcon.main {
    display: none;
  }

  .logoIcon {
    display: initial;
    margin-top: 0.25rem;
  }

  .mainHeader {
    padding: 0.75rem 1rem;
  }

  .categoryList {
    margin-top: 3rem;
    padding: 1.1875rem 1rem;
  }
}
